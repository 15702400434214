<template>
  <section class="Pagination">
    <el-pagination background :pager-count="3" :page-size="pageSize" layout="prev, pager, next" :total="total" hide-on-single-page
      @current-change="handleChangeCurrentPage">
    </el-pagination>
  </section>
</template>
<script>
  export default {
    name: "Pagination",
    props: {
      total: {
        type: [Number, String],
        default: 1
      },
      pageSize: {
        type: Number,
        default: 12
      }
    },
    data() {
      return {};
    },
    methods: {
      handleChangeCurrentPage(e) {
        this.$emit('changeCurrentPage', e)
      },
    },
    created() { },
  };
</script>
<style src='' lang='scss' scoped>
</style>