<template>
  <!-- 商城  积分商品 -->
  <div class="content-page">
    <!-- 导航栏 -->
    <b-card class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/webstore' }">Webstore</el-breadcrumb-item>
        <el-breadcrumb-item>Gift Shop</el-breadcrumb-item>
      </el-breadcrumb>
    </b-card>
    <div class="row">
      <div class="col-12 col-md-3">
        <b-card class="mt-3 p-3">
          <p>Reward Points</p>
          <p class="d-flex align-items-center">
            <img src="@/assets/images/page/reward.png" alt="" style="width: 20px;">
            <strong class="pl-3">{{points}} Points</strong>
          </p>
          <hr />
          <p class="mb-0 cursor-pointer" style="color: #50b5ff" @click="$router.push('/myexchange')">Exchange records
          </p>
        </b-card>
        <b-card class="mt-3 p-3">
          <h4 class="pb-3">Filters</h4>
          <form action="#" class="searchbox">
            <b-input type="text" placeholder="Search" v-model="params.keyword"></b-input>
            <a href="#"><i class="ri-search-line text-muted"></i></a>
          </form>
          <h6 class="mt-3">Points</h6>
          <div class="pt-2">
            <input class="int" type="text" placeholder="50" v-model="params.min_points" /> -
            <input type="text" class="int" placeholder="150" v-model="params.max_points" />
          </div>
          <h6 class="mt-3">Type</h6>
          <div class="pt-2 category">
            <el-select v-model="params.points_type" style="width: 100%;" clearable placeholder="">
              <el-option v-for="item in typeOptions" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </div>
          <section v-if="params.points_type!=1">
            <h6 class="mt-3">Category</h6>
            <div class="pt-2 category">
              <el-cascader :options="classifyOptions" style="width: 100%;" clearable :show-all-levels="false"
                v-model="params.classify_id" placeholder=""
                :props="{children: 'subset',value: 'id',label: 'name',checkStrictly: true,emitPath: false}" filterable>
              </el-cascader>
            </div>
            <h6 class="mt-3">Country</h6>
            <div class="pt-2 category">
              <el-select v-model="params.country_name" style="width: 100%;" clearable placeholder="">
                <el-option v-for="item in countryList" :key="item.id" :label="item.name" :value="item.name">
                </el-option>
              </el-select>
            </div>
          </section>
          <button class="btn" @click="page=1,_pointsProducts()">Filter</button>
        </b-card>
      </div>
      <div class="col-12 col-md-9">
        <div class="row" v-if="pointsProductsList.length">
          <div class="col-lg-4 col-6" v-for="(v,i) of pointsProductsList" :key="i">
            <b-card class="mt-3">
              <div class="card cursor-pointer my_product_card" @click="$router.push(`/shopscoredetails?id=${v.id}`)">
                <img :src="v.product_image" class="card-img-top" alt="" />
                <div class="card-body content_box">
                  <h6 class="card-title">{{v.product_name}}</h6>
                  <p class="card-text mb-1">{{v.points}} Points</p>
                </div>
              </div>
            </b-card>
          </div>
        </div>
        <div class="d-flex justify-content-center align-items-center" style="background:#fff;width: 100%;height: 100%;" v-else>
          <img style="width:250px;" src="@/assets/images/group/empty1.png" alt="" />
        </div>
        <div class="d-flex justify-content-end mt-3">
          <Pagination :total="total" @changeCurrentPage="changeCurrentPage" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Pagination from "@/components/ComComponents/Pagination";

  export default {
    components: { Pagination },
    data() {
      return {
        classifyOptions: [],
        typeOptions: [
          { id: 1, name: "Sticker" },
          { id: 0, name: "Point product" }
        ],
        params: {
          keyword: '',
          classify_id: '',
          min_points: '',
          max_points: '',
          country_name: '',
          points_type: ''
        },
        points: 0,
        pointsProductsList: [],
        countryList: [],
        total: 0
      };
    },

    created() {
      this._pointsProducts()
      this._goodsCate()
      this._getAddress()
    },

    methods: {
      changeCurrentPage(e) {
        this._pointsProducts(e);
      },

      // 国家列表
      async _getAddress() {
        let res = await this.$http.getAddress({ level: 2 })
        if (res.status == 200) {
          this.countryList = res.data
        }
      },

      // 积分商品列表
      async _pointsProducts(page = 1) {
        if (this.params.points_type == 1) {
          this.params.country_name = ""
          this.params.classify_id = ""
        }
        const data = {
          page,
          ...this.params,
          per_page: '',
        };
        let res = await this.$http.pointsProducts(data)
        if (res.status == 200) {
          this.points = res.data.points
          this.pointsProductsList = res.data.point_goods.data
          this.total = res.data.point_goods.total
        }
      },

      // 分类列表
      async _goodsCate() {
        let res = await this.$http.goodsCate()
        if (res.status == 200) {
          this.classifyOptions = res.data
        }
      }

    },
  };
</script>

<style lang="scss" scoped>
  .card-body {
    padding: 0;
  }

  .breadcrumb {
    background: #fff;
    height: 60px;
    margin-bottom: 0;

    .card-body {
      display: flex;
      align-items: center;
    }

    ::v-deep .el-breadcrumb__inner.is-link {
      color: #50B5FF;
      font-weight: 500;
    }
  }

  .content_box {
    h6 {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .category {
    ::v-deep input.el-input__inner {
      height: 45px;
    }
  }

  .searchbox {
    width: 100%;

    input {
      position: relative;
    }

    a {
      position: absolute;
      top: 70px;
      right: 30px;
    }
  }

  .int {
    width: 110px;
    height: 35px;
    background: #f3f4f5;
    border: 1px solid #f3f4f5;
    border-radius: 5px;
    text-align: center;
  }

  .btn {
    width: 100%;
    border: 1px solid #50b5ff;
    color: #50b5ff;
    margin-top: 20px;
  }

  .card-title {
    padding: 20px 11px;
    font-size: 16px;
    font-family: Montserrat Medium, Montserrat Medium-Medium;
    font-weight: 500;
    color: #666666;
  }

  .card-text {
    padding-left: 13px;
    font-size: 16px;
    font-family: Poppins SemiBold, Poppins SemiBold-SemiBold;
    font-weight: SemiBold;
    color: #000000;
  }

  .my_product_card {
    img {
      height: 198px;
      object-fit: cover;
    }
  }

  @media (max-width: 1367px) {
    .my_product_card {
      img {
        height: 120px;
        object-fit: cover;
      }
    }
  }
</style>